/* Home.css */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #000000;
    min-height: 70vh;
  }
  
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally centers the content */
    justify-content: flex-start; /* Aligns the content at the top */
    height: 70vh;
    background-color: #000000;
    color: #FF0000
    ;
    text-align: center;
    padding: 4rem;
    padding-top: 2rem; /* Adds some padding to the top */
  }
  
  .home-heading {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .home-links {
    list-style-type: none;
    padding: 0;
    text-decoration: underline;
  }
  
  .home-links li {
    margin: 0.5rem 0;
  }
  
  .home-links a {
    color: #FF0000

    ;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
  .home-links a:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for Responsiveness */
  
  /* Small devices (portrait tablets and large phones, 600px and below) */
  @media (max-width: 600px) {
    .home-heading {
      font-size: 2rem;
    }
  
    .home-links a {
      font-size: 1rem;
    }
  }
  
  /* Medium devices (landscape tablets and small desktops, 900px and below) */
  @media (max-width: 900px) {
    .home-heading {
      font-size: 2.5rem;
    }
  
    .home-links a {
      font-size: 1.1rem;
    }
  }
  
  /* Large devices (large desktops, 1200px and below) */
  @media (max-width: 1200px) {
    .home-heading {
      font-size: 2.8rem;
    }
  
    .home-links a {
      font-size: 1.15rem;
    }
  }
  